
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useVehicleType } from '@/composable/useVehicleType'
import { Vehicle } from '@/types/vehicle'
import {
  validDateRule,
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { format, isAfter, addMonths } from 'date-fns'
import { VehicleValidPeriod } from '@/types/vehicle-valid-period'
import { useProfile } from '@/composable/useProfile'
import { workshops } from '@/common/workshops'

export default defineComponent({
  name: 'MasterVehicle',

  components: {
    MasterTable,
    AppFieldDate,
  },

  setup() {
    const {
      loading: loadingStateVehicles,
      data: vehicles,
      add,
      update,
      remove,
    } = useVehicle(true)
    const { currentProject } = useProfile()
    const { loading: loadingStateVehicleTypes, data: vehicleTypes } =
      useVehicleType(true)

    function createFormModel(): Vehicle {
      return {
        uuid: uuidv4(),
        name: '',
        vehicleTypeUuid: null,
        seating: 0,
        workshop: null,
        vehicleValidPeriods: [
          {
            uuid: uuidv4(),
            periodStart: format(new Date(), 'yyyy-MM-dd'),
            periodEnd: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
          },
        ],
      }
    }
    const model = ref<Vehicle>(createFormModel())

    function findVehicleTypeByUuid(uuid: string | null) {
      if (!uuid) return null
      const vehicleType = vehicleTypes.value.find(
        (vehicleType) => vehicleType.uuid === uuid
      )

      if (!vehicleType) return null

      return vehicleType.name
    }

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'vehicleType',
        label: 'Fordonstyp',
        align: 'left',
        field: (v: Vehicle) => findVehicleTypeByUuid(v.vehicleTypeUuid),
        sortable: true,
      },
      {
        name: 'name',
        label: 'Fordon',
        align: 'left',
        field: 'name',
        sortable: true,
      },
      {
        name: 'seating',
        label: 'Sittplatser',
        align: 'left',
        field: 'seating',
        sortable: true,
      },
      {
        name: 'workshop',
        label: 'Verkstadstillhörighet',
        align: 'left',
        field: 'workshop',
        sortable: true,
      },
      {
        name: 'periodStart',
        label: 'Senaste gilltig från',
        align: 'left',
        field: (x: Vehicle) =>
          x.vehicleValidPeriods.length
            ? format(
                new Date(
                  x.vehicleValidPeriods[
                    x.vehicleValidPeriods.length - 1
                  ]?.periodStart
                ),
                'yyyy-MM-dd'
              )
            : '',
        sortable: true,
      },
      {
        name: 'periodEnd',
        label: 'Senaste gilltig till',
        align: 'left',
        field: (x: Vehicle) =>
          x.vehicleValidPeriods.length
            ? format(
                new Date(
                  x.vehicleValidPeriods[
                    x.vehicleValidPeriods.length - 1
                  ]?.periodEnd
                ),
                'yyyy-MM-dd'
              )
            : '',
        sortable: true,
      },
    ].filter((x) =>
      currentProject.value?.name === 'krosatag' ? true : x.name !== 'workshop'
    )

    const loading = computed(() => {
      return {
        ...loadingStateVehicles.value,
        getAll:
          loadingStateVehicles.value.getAll ||
          loadingStateVehicleTypes.value.getAll,
      }
    })

    const exportData = computed(() => {
      return vehicles.value.map((vehicle) => {
        return {
          Fordonstyp: findVehicleTypeByUuid(vehicle.vehicleTypeUuid),
          Fordons: vehicle.name,
          Sittplatser: vehicle.seating,
        }
      })
    })

    function addVehicleValidPeriod() {
      if (!model.value.vehicleValidPeriods) {
        model.value.vehicleValidPeriods = []
      }
      model.value.vehicleValidPeriods.push({
        uuid: uuidv4(),
        periodStart: format(new Date(), 'yyyy-MM-dd'),
        periodEnd: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
      })
    }

    function updateVehicleValidPeriod(
      uuid: string,
      period: string,
      periodProp: 'periodStart' | 'periodEnd'
    ) {
      model.value.vehicleValidPeriods = model.value.vehicleValidPeriods.reduce<
        VehicleValidPeriod[]
      >((acc, vehicleValidPeriod) => {
        if (vehicleValidPeriod.uuid === uuid) {
          acc.push({
            ...vehicleValidPeriod,
            [periodProp]: period,
          })
        } else {
          acc.push(vehicleValidPeriod)
        }
        return acc
      }, [])
    }

    function removeVehicleValidPeriod(vehicleValidPeriod: VehicleValidPeriod) {
      model.value.vehicleValidPeriods = model.value.vehicleValidPeriods.filter(
        (x) => x.uuid !== vehicleValidPeriod.uuid
      )
    }

    function isValidPeriod(vehicleValidPeriod: VehicleValidPeriod) {
      return isAfter(
        new Date(vehicleValidPeriod.periodEnd),
        new Date(vehicleValidPeriod.periodStart)
      )
    }

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      vehicles,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
      loadingStateVehicleTypes,
      vehicleTypes,
      addVehicleValidPeriod,
      updateVehicleValidPeriod,
      removeVehicleValidPeriod,
      format,
      validDateRule,
      isValidPeriod,
      currentProject,
      workshops,
    }
  },
})
